import React from "react";
import { Navbar, Container } from 'reactstrap';


const Footer = (props) => {

      return(
        <>
          <div className="fixed-bottom">
              <Navbar color="white" dark>
                  <Container>
                  <div className="small_font">Copyright &copy; 2022 Daphistian Labs v22-06-10</div>
                  </Container>
              </Navbar>
          </div>
          </>
      );
};

export default Footer;
