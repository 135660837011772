import React from "react";

import Menu from './Menu';
import { PLACES } from '../shared/places';

const Home = (props) => {

    //const [my_places] = useState(0);
    return (<>
              <div className="wrapper">
                    <Menu places={PLACES} />
                </div>
                <br />
                <br />
                <br />
            </>
    );
};

export default Home;
