import React from "react";

const Login = (props) => {

    //const [my_places] = useState(0);
    return (
      <>
        <div className="wrapper">
        <br />
        <br />
          <p className="my_center">
            <label>
              {/* {LABELS[0].login} */}
              <input type="text" name="name" />
            </label>
            <br />
            <label>
              {/* {LABELS[0].password}  */}
            <input type="text" name="password" />
            </label>
            <br />
            <input type="submit" value="Submit" />
          </p>

        </div>
      </>
    );
};

export default Login;
