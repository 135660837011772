export const PLACES =
	[
			{
				id: 0,
				name: 'Quantum proof protocols',
				image: './images/DLogo50x50.jpg',
				category: 'Quantum',
				description: 'Daphistian Labs specializes in securing  systems against intrusion by quantum-based and traditional computers',
				source: ''
			},{
				id: 1,
				name: 'Quantum algorithms',
				image: './images/DLogo50x50.jpg',
				category: 'Quantum',
				description: 'Daphistian labs creates quantum-based computing algorithms tailored for security, simulation, and modeling',
				source: ''
			},{
				id: 2,
				name: 'Consulting',
				image: './images/DLogo50x50.jpg',
				category: 'Security/Quantum',
				description: 'Our consulting services focus on Quantum-based algorithms and Protocols that enhance your value proposition providing the confidence your organization requires and your customers expect and demand',
				source: ''
			}
	];
