export const D_TEXT =
	[
			{
				id: 0,
				header: 'Overview',
				text_0: 'Daphistian Laboratories specializes in designing advanced security encryption ' +
				'protocols and systems that are impervious to intrusion by quantum computers. Our ' +
				'protocols utilizes advanced algorithms to maintain perfect security for secured ' +
				' transmissions over public systems, non-secured devices, and IoT in general.' +
				'Applications for the Daphistian Protocol include securing financial transfer systems, ' +
				'small devices, drone and weapons systems, blockchain back-ends, communication systems, ' +
				'and information technology systems.  ' +
				'Traditional encryption is limited by intensive hardware and/or software requirements and, as a  ' +
				'result, is not well suited for constrained devices.',
				text_1:
				'Our patent pending Daphistian Protocol ' +
				'however is able to deliver beyond-algorithmic secrecy using a minimal amount  ' +
				'of power and computational system requirements, allowing advanced degrees of encryption  ' +
				'security for small devices, such as UAV’s and remote sensors, permitting longer operational  ' +
				'duration and more secure actions. ',
				image: './images/DLogo50x50.jpg',
				category: 'Quantum'
			},
			{
				id: 1,
				header: 'Our Team',
				text_0: 'Phil Bradford - CTO Development Computer scientist with extensive experience in academia and industry. ' +
				'Phil was a post-doctoral fellow at the Max-Planck-Institute für Informatik. He earned his PhD at ' +
				'Indiana University, an MS from the University of Kansas, and a BA from Rutgers University. ' +
				'Phil was on the faculty at Rutgers Business School and the University of Alabama School of Engineering.' +
				' He has worked for General Electric, BlackRock, Reuters Analytics, founded a firm and both occasionally ' +
				'consults and works with a number of early-stage firms.' +
				'Currently, Phil is on the faculty of the computer science program at the University of Connecticut, Stamford.',

				text_1:
				'Steve Davis- CTO Research Mr. Davis has over 30 years of experience in the electronics industry. ' +
				'He has expert knowledge in wireless communication systems design and holds 14 technology patents. ' +
				'He is the former Director of Advance Research at Atari and Warner Communications and oversaw the ' +
				'development of their wireless gaming devices, interactive two-way cable network and billing systems, ' +
				'and Antenna Designs. He held the positions of President of Engineering at SCA Data Systems which ' +
				'focused on radio station data equipment and data receivers for use in satellite and FM sub-carrier ' +
				'communications and Cue Corporation which was a Wireless FM sub-carrier focused on paging equipment, ' +
				'small antenna design. One-way secure transmissions protecting the source of transmission. Micro- antenna ' +
				'designs. High efficiency small receive and transmit antennas from 10 MHz to 3 GHz. He also held ' +
				'the position as Head of Engineering for Point Research Corporation that developed and implements ' +
				'Dead reckoning devices using magnetic sensors in conjunction with accelerometers for military use. ' +
				'Micro-controller and processor design and machine code encryption.',

				text_2:
				'Stephen Rust Business Development & Facility Security Officer (FSO) Retired Army Colonel, 29 years active duty with 18 years in acquisitions. Roles in acquisition included product and project management. Served in international cooperation in the Office of the Secretary of Defense.' +
				'Steve served as general manager for a defense and law enforcement business development firm.',

				text_3:
				'Daniel Schwartz CEO/CFO Banking, international trade, and technology executive. ' +
				'Responsible for financial reporting, budgeting, debt and equity financial structuring, and ' +
				'financial operations management for private equity funds and their portfolio investments, public ' +
				' and private corporations, multinationals, and NGO’s. Former representative for United Nations ' +
				'Procurement and for the Bi-National Industrial Research and Development Fund. He was a managing ' +
				'director of a publicly traded international technology company and launched their investment fund and '  +
				'technology incubator. Daniel also adjuncts at the University of Connecticut, Stamford.' +
				'The company is a CT limited liability company with corporate offices in Stamford, CT, technology ' +
				'development in Stamford, CT and Palm Desert, CA.',

				text_4: 'Ian Schwartz, of Intermodal Finance Law P.C., is the company’s General Counsel.'
			}
	];
