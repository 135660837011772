import React from 'react'
import { Link } from 'react-router-dom';

import './media.css';

/*
const testF = () => {
    alert("test");
}
*/

const sendMailPhil = () => {
  const mailto: string =
    "mailto:phil@daphistian-labs.com";
    window.location.href = mailto;
  };

  const sendMailDan = () => {
    const mailto: string =
      "mailto:daniel@daphistian-labs.com";
      window.location.href = mailto;
    };

function Contact() {


    return (<>
      <br/>
      <br />
      <h3 style={{ color: 'royalblue' }}>Contact Us</h3>
      <br/>
      <br/>
      <div >
                  <h4>
                  Please contact Phillip Bradford at
                  </h4>
                  <h5>
                  phil@daphistian-labs.com
                 </h5>
                 <h4>
                 <input type="button" value="Send email"
                  onClick={sendMailPhil} />
                 </h4>
             <br />
             <br />

                 <h4>
                   Please contact Daniel Schwartz at
                   </h4>
                   <h5>
                   daniel@daphistian-labs.com
                   </h5>
                 <h4>
                 <input type="button" value="Send email"
                  onClick={sendMailDan} />
                 </h4>

           </div>
           <br />
           <br />
           <br />
           <br />
           <Link to='/Login'>Login</Link>
           <br />
           <br />
           <br />
        </>
    );
};

export default Contact;
