export const NEWS_TEXT =
	[
			{
				id: 0,
				header: 'News!',
				text_0: '',
				text_1: '',

				image: './images/DLogo50x50.jpg',
				category: 'Quantum'
			},
			{
				id: 1,
				header: 'Our Team',
				text_0: 'Phil Bradford - CTO Development Computer scientist with extensive experience in academia and industry. ',
				text_1: '',

				image: './images/DLogo50x50.jpg',
				category: 'Quantum'
			}
	];
