import React from 'react';
import Logo from '../images/DLogo.jpg';
import './media.css';

class Greet extends React.Component {

	constructor(props){
		super(props);

		this.state = {val: "awesome"};
	}


	changeValue = () => {
		this.setState(
			{val: "wonderful"}
			);
		}

	render(){
		return(
		<div className="container">
			<div className="image">
				<img
						img src={Logo}
						style={{height:"130px",width:"130px"}}
						alt="Daphistian Laboratories"
					/>
				</div>
				<div className="text">
					<h1>Daphistian Labs</h1>
					<h2>Hands on results</h2>
				</div>
	</div>
		);
	}
}


export default Greet;
