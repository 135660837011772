import React from 'react';
import { D_TEXT } from './docs/Text';

import './media.css';

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function About() {

  return (
    <>
    <br/>
    <br/>
      <h3 style={{ color: 'royalblue' }}>{D_TEXT[1].header}</h3>
      <br />
      <br />
          <p className="ctr_outer">
            <p className="ctr_inner">
              {D_TEXT[1].text_0}
            </p>
            <p className="ctr_inner">
              {D_TEXT[1].text_1}
            </p>
            <p className="ctr_inner">
              {D_TEXT[1].text_2}
            </p>
            <p className="ctr_inner">
              {D_TEXT[1].text_3}
            </p>
            <p className="ctr_inner">
              {D_TEXT[1].text_4}
            </p>
          </p>

          <br />
          <br />
          <br />

  </>
  );
}

export default About;
