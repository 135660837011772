import React from 'react';
import './media.css';
import { D_TEXT } from './docs/Text';
import './media.css';
import 'bootstrap/dist/css/bootstrap.css';


//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function Overview() {

  return (
    <>
      <br/>
      <br/>
      <h3 style={{ color: 'royalblue' }}>{D_TEXT[0].header}</h3>
      <br/>
      <br/>
      <div>
      <p className="ctr_outer">
            <p className="ctr_inner">
                {D_TEXT[0].text_0}
            </p>
            <p className="ctr_inner">
              {D_TEXT[0].text_1}
            {/* <AllPagesPDFViewer pdf={aboutUsPdf} /> */}
            </p>
          </p>
        </div>

        <br />
        <br />
        <br />
    </>
  );
}

export default Overview;
