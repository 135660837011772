import React from 'react';
import './media.css';
import { NEWS_TEXT } from './docs/News';
import './media.css';
import 'bootstrap/dist/css/bootstrap.css';

import YoutubeEmbed from "./YoutubeEmbed";

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function Overview() {

  return (
    <>
      <br/>
      <br/>
      <h3 style={{ color: 'royalblue' }}>{NEWS_TEXT[0].header}</h3>
      <br/>
      <br/>

      <br />
      <p className="ctr_video">
        <h3> University of Connecticut, Sr. Design Team</h3>
        <h4 style={{ color: "blue" }}> Financial Transfer System - 2022-05-07 </h4>
          <YoutubeEmbed embedId="t_eRNlXlXk4" />
      </p>
      <p className="ctr_video">
        <h3> University of Connecticut, Sr. Design Team</h3>
        <h4 style={{ color: "blue" }}> Quantum Computing - 2022-05-07 </h4>
          <YoutubeEmbed embedId="v9AKGalocA8" />
      </p>
    </>
  );
}

export default Overview;
