import React from 'react';
import { Media } from 'reactstrap';
import './media.css';

class Menu extends React.Component {
	constructor(props){
		super(props);

		this.state = {};
	}

	render(){

		const menu = this.props.places.map((place) => {
			return(
				<div key={place.id} id="unit"  className="col-12 mt-5">

					<Media tag="li" >
						<Media body className="MyMenu" >
							<Media heading><strong>{place.name}</strong></Media>
							{/* <div><strong></strong>{place.category}</div> */}
							<div>{place.description}</div>
						</Media>
					</Media>

				</div>
			);
		});

		return(
			<div className="container">
				<div className="row">
					<Media list>
						{menu}
					</Media>
				</div>
			</div>
		);
	}
}

// 'Keys' - It helps identify which items have changed, are added or removed.


export default Menu;
