import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, NavbarBrand, NavLink, Container } from 'reactstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
		Redirect
  } from "react-router-dom";
import { PLACES } from './shared/places';

import Greet from './components/Greet';

import About from './components/About.js';
import Contact from './components/Contact.js';
import Footer from './components/Footer.js';
import Home from './components/Home.js';
import Login from './components/Login.js';
import News from './components/News.js';
import Overview from './components/Overview.js';


class App extends React.Component {

	toggleNavbar() { this.setState({ collapsed : !this.state.collapsed }); }

	constructor(props){
		super(props);

		this.state = {
			places: PLACES,
			collapsed: false
		};
	}

	render(){
		return (
			<div className='App'>
  			<Router>
    			<Navbar bg="light" className="ml-auto" expand="lg" color="primary">
    				<Container>
    					<NavbarBrand href="./Home" className="navItem" >Daphistian Labs</NavbarBrand>
    					<NavLink href="./Overview" className="navItem" onClick={()=>this.toggleNavbar()}>Overview</NavLink>
              <NavLink href="./Contact" className="navItem">Contact us</NavLink>
              <NavLink href="./News" className="navItem">News</NavLink>
    					<NavLink href="./About" className="navItem">About</NavLink>
    				</Container>
    			</Navbar>
  			<Greet />
  			<Navbar color="primary">
  			</Navbar>
        <br />
  			<h3>Targeted development and applied research</h3>
  			{/* <Menu places={this.state.places} /> */}
  			{/*	this.state.collapsed && <Menu places={this.state.places} /> */}
  			<Switch>
  				<Route exact path="/">
      			<Redirect to="/Home" />
  				</Route>
  				 <Route path="/Home">
  						<Home places={this.state.places}/>
  					</Route>
  	        <Route path="/Overview">
  	            <Overview />
  	        </Route>
            <Route path="/Contact">
  	            <Contact />
  	        </Route>
  					<Route path="/About">
  							<About />
  					</Route>
            <Route path="/News">
  							<News />
  					</Route>
            <Route path="/Login">
  							<Login />
  					</Route>
          </Switch>
  			 </Router>
         <Footer />
			</div>
		);
	}
}

export default App;
